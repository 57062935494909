import MusicNoteIcon from '@mui/icons-material/MusicNote';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import NightlifeIcon from '@mui/icons-material/Nightlife';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import BiotechIcon from '@mui/icons-material/Biotech';
  
  export const IndustryOptions = [
    { id: 1, label: 'music', icon: <MusicNoteIcon size={20} /> },
    { id: 2, label: 'cook', icon: <RestaurantIcon size={20} /> },
    { id: 3, label: 'language', icon: <GTranslateIcon size={20} /> },
    { id: 4, label: 'dance', icon: <NightlifeIcon size={20} /> },
    { id: 5, label: 'art', icon: <ColorLensIcon size={20} /> },
    { id: 6, label: 'business', icon: <BusinessCenterIcon size={20} /> },
    { id: 7, label: 'yoga', icon: <SelfImprovementIcon size={20} /> },
    { id: 8, label: 'sports', icon: <SportsBasketballIcon size={20} /> },
    { id: 9, label: 'fitness', icon: <MonitorHeartIcon size={20} /> },
    { id: 10, label: 'tech', icon: <BiotechIcon size={20} /> }
    
  ];