import { Container, Typography } from '@mui/material';
import React from 'react';
import TopNavBar from '../TopNavBar/TopNavBar';


function Privacy() {
    return (
       <Container style={{padding:'5%'}}>
        <TopNavBar back='/' />


        <Typography variant='h3' mt={4}>
            Privacy Policy
        </Typography>
        <br/>
        <br/>
        <Typography>
        Osmose Inc. takes your privacy seriously.
        <br/>
        <br/>
        Your privacy is important to us. Osmose, Inc.  (“Osmose,” “we” or “us”) is committed to respecting the privacy of our customers. Unless we link to a different policy or state otherwise, this Privacy Notice applies when you visit or use any of our websites (“Sites”), our mobile applications, or related services (collectively the “Services”). By using the Services, you agree to the terms of this Privacy Notice. You shouldn’t use the Services if you don’t agree with this Privacy Notice or any other agreement that governs your use of the Services. 
        <br/>
        <br/>
        This Privacy Notice describes Osmoses’ policies and practices regarding our collection and use of your personal data or information and sets forth your privacy rights. 



        </Typography>

        <ol>
          <li>Table of Contents</li>
          <br/>
          <li>Types of Information</li>
          <br/>
          <li>Types of Information We Collect</li>
          <br/>
          <li>How We Use Your Information </li>
          <br/>
          <li>Whom We Share Your Data With</li>
          <br/>
          <li>Security of Your Data </li>
          <br/>
          <li>Your Rights </li>
          <br/>
          <li>Other Important Information </li>
        </ol>

        <br/>
        <br/>

        <Typography variant='h6'>
            Types of Information We Collect
        </Typography>
        <br/>
        <br/>

        <Typography>
        Information we collect includes both information you knowingly and actively provide us when using or participating in any of our Services, and any information automatically sent by your devices while accessing our Services.  The following provides examples of the type of information that we collect from you and how we use that information.
        </Typography>
        <br/>
        <br/>
        <Typography>

        Context: Account Information
        <br/><br/>
        Types of Data: When you create or update your account, we collect and store the data you provide, such as your name, email address, password, profile photo, and account settings, and assign you a unique identifying number (“Account”).
        <br/><br/>
        Primary Purpose for Collection & Use: We have a legitimate interest in providing account-related functionalities to our users, monitoring account logins, and detecting potential fraudulent logins or account misuse. Additionally, we use this information to fulfill our contract to provide you with Services. 
        <br/><br/>
        Context: Cookies and First-Party Tracking
        <br/><br/>
        Types of Data: We use cookies and clear GIFs. “Cookies” are small pieces of information that a website sends to a computer’s hard drive while a website is viewed. See our Cookie Policy for more information.
        <br/><br/>
        Primary Purpose for Collection & Use: We have a legitimate interest in making our Sites operate efficiently.
        <br/><br/>
        Context: Cookies and Third-Party Tracking
        <br/><br/>
        Types of Data: We participate in behavior-based advertising, this means that a third party uses technology (e.g., a cookie) to collect information about your use of our Sites so that they can provide advertising about Services tailored to your interests on our Sites, or on other websites.
        <br/><br/>
        Primary Purpose for Collection & Use: We have a legitimate interest in engaging in behavior-based advertising and capturing website analytics.
        <br/><br/>
        Context: Demographic Information
        <br/><br/>
        Types of Data: We collect personal information, such as your location.
        <br/><br/>
        Primary Purpose for Collection & Use: We have a legitimate interest in understanding our users and providing tailored services.
        <br/><br/>
        Context: Distance Information
        <br/><br/>
        Types of Data: When you use one of our apps, we collect your location from your device's GPS, Wi-Fi, and/or cellular technology to determine your location to sell our Services.
        <br/><br/>
        Primary Purpose for Collection & Use: We have a legitimate interest in understanding our users and providing tailored services. In some contexts, our use is also based upon your consent to provide us with geo-location information.
        <br/><br/>
        Context: Email Interconnectivity
        <br/><br/>
        Types of Data: If you receive email from us, we use certain tools to capture data related to when you open our message, click on any links or banners it contains, and make purchases.
        <br/><br/>
        Primary Purpose for Collection & Use: We have a legitimate interest in understanding how you interact with our communications to you.
        <br/><br/>
        Context: Employment
        <br/><br/>
        Types of Data: If you apply for a job posting, or become an employee, we collect information necessary to process your application or to retain you as an employee. This may include, among other things, your Social Security Number. Providing this information is required for employment.
        <br/><br/>
        Primary Purpose for Collection & Use: We use information about current employees to perform our contract of employment or the anticipation of a contract of employment with you. In some contexts, we are also required by law to collect information about our employees. We also have a legitimate interest in using your information to have efficient staffing and workforce operations.
        <br/><br/>
        Context: Feedback/Support
        <br/><br/>
        Types of Data: We collect personal data from you contained in any inquiry you submit to us regarding our Sites or Services, such as completing our online forms, calling, or emailing for the purposes of general inquiries, support requests, or reporting an issue. When you communicate with us over the phone, your calls may be recorded and analyzed for training, quality control, and sales and marketing purposes. During such calls, we will notify you of the recording via either voice prompt or script.
        <br/><br/>
        Primary Purpose for Collection & Use: We have a legitimate interest in receiving, and acting upon, your feedback, issues, or inquiries.
        <br/><br/>
        Context: Mailing List
        <br/><br/>
        Types of Data: When you sign up for one of our mailing lists we collect your email address or postal address.
        <br/><br/>
        Primary Purpose for Collection & Use: We share information about our Services with individuals that consent to receive such information. We also have a legitimate interest in sharing information about our Services.
        <br/><br/>
        Context: Mobile Devices
        <br/><br/>
        Types of Data: We collect information from your mobile device such as unique identifying information broadcast from your device when visiting our Sites.
        <br/><br/>
        Primary Purpose for Collection & Use: We have a legitimate interest in identifying unique visitors, and in understanding how users interact with us on their mobile devices.
        <br/><br/>
        Context: Order Placement
        <br/><br/>
        Types of Data: We collect your name, billing address, e-mail address, phone number, and credit card number when you place an order.
        <br/><br/>
        Primary Purpose for Collection & Use: We use your information to perform our contract to provide you with Services.
        <br/><br/>
        Context: Surveys
        <br/><br/>
        Types of Data: When you participate in a survey, we collect information that you provide through the survey. If the survey is provided by a third-party service provider, the third party’s privacy policy applies to the collection, use, and disclosure of your information.
        <br/><br/>
        Primary Purpose for Collection & Use: We have a legitimate interest in understanding your opinions and collecting information relevant to our organization.
        <br/><br/>
        Context: Profile Data
        <br/><br/>
        Types of Data: You can also choose to provide profile information like a photo, headline, biography, country, or other data. (“Profile Data”) Your Profile Data will be publicly viewable by others.
        <br/><br/>
        Primary Purpose for Collection & Use: We have a legitimate interest in understanding your preferences and interests to select offerings that you might find most useful. We also have a legitimate interest in detecting and preventing fraud.
        <br/><br/>
        Context: Shared Content
        <br/><br/>
        Types of Data: Parts of the Services let you interact with other users or share content publicly, including by posting reviews on a course page, asking, or answering questions, sending messages to students or instructors, or posting photos or other work you upload. Such shared content may be publicly viewable by others depending on where it is posted (“Shared Content”).
        <br/><br/>
        Primary Purpose for Collection & Use: We have a legitimate interest in understanding your preferences and interests to select offerings that you might find most useful, as well as to help the services function.
        <br/><br/>
        Context: Website interactions
        <br/><br/>
        Types of Data: We use technology to monitor how you interact with our Sites. This may include which links you click on, or information that you type into our online forms. This may also include information about your device or browser.
        <br/><br/>
        Primary Purpose for Collection & Use: We have a legitimate interest in understanding how you interact with our Sites to better improve it and to understand your preferences and interests to select offerings that you might find most useful. We also have a legitimate interest in detecting and preventing fraud.
        <br/><br/>
        Context: Web Logs
        <br/><br/>
        Types of Data: We collect information, including your browser type, operating system, Internet Protocol (IP) address (a number that is automatically assigned to a computer when the Internet is used), domain name, click-activity, referring website, and/or a date/time stamp for visitors.
        <br/><br/>
        Primary Purpose for Collection & Use: We have a legitimate interest in monitoring our networks and the visitors to our websites. Among other things, it helps us understand which of our services is the most popular.
        <br/><br/>
        In addition to the information that we collect from you directly, we may also receive information about you from other sources, including third parties, business partners, our affiliates, or publicly available sources. For example, if you submit a job application, or become an employee, we may conduct a background check.
        <br/>
        <br/>

        How We Use Your Information
            <br/>
            <br/>
        In addition to the purposes and uses described above, we use information in the following ways:
            <br/>
            <br/>
        <ul>
            <li>To enable you to customize or personalize your experience of our Sites. </li>
                <br/> 
            <li>For analytics, market research, and business development, including operating and improving our Sites, associated applications, and associated social media platforms. </li>
                <br/>
            <li>For advertising and marketing, including sending you promotional information about our Services and information about third parties that we consider may be of interest to you. </li>
                <br/>
            <li>To consider your employment application. </li>
                <br/>
            <li>To comply with our legal obligations and resolve any disputes that we may have. </li>
                <br/>
            <li>For security and fraud prevention, and to ensure that our sites and apps are safe, secure, and used in line with our terms of use. </li>
        </ul>

        <br/>

        Although the sections above describe our primary purpose in collecting your information, in many situations, we have more than one purpose.  For example, if you sign up for our Services, we may collect your information to complete that transaction, but we also collect your information as we have a legitimate interest in maintaining your information after your transaction is complete so that we can quickly and easily respond to any questions about your Services.  As a result, our collection and processing of your information are based in different contexts upon your consent, our need to perform a contract, our obligations under the law, and/or our legitimate interest in conducting our business.

        </Typography>

        <br/>
        <br/>

        <Typography>
            Whom We Share Your Data With:
            <br/>
            <br/>
            In addition to the specific situations discussed elsewhere in this policy, we disclose information in the following situations:
            <br/>
            <ul>
                <li>With Other Students and Instructors: Depending on your settings, your Shared Content and Profile Data may be publicly viewable, including to other students and instructors. If you ask a question to an instructor or teaching assistant, your information (including your name) may also be publicly viewable by other users depending on your settings.</li>
                <br/>
                <li>Affiliates and Acquisitions: We may share information with our corporate affiliates (e.g., parent company, sister companies, subsidiaries, joint ventures, or other companies under common control).  If another company acquires or plans to acquire, our company, business, or our assets, we will also share information with that company, including at the negotiation stage.  </li>
                <br/>
                <li>Other Disclosures with Your Consent:  We may ask if you would like us to share your information with other unaffiliated third parties who are not described elsewhere in this Privacy Notice.</li>
                <br/>
                <li>Other Disclosures without Your Consent: We may disclose information in response to subpoenas, warrants, or court orders, in connection with any legal process, or to comply with relevant laws.  We may also share your information to establish or exercise our rights, to defend against a legal claim, to investigate, prevent, or act regarding possible illegal activities, suspected fraud, the safety of a person or property, or a violation of our policies, or to comply with your request for the shipment of products to or the provision of services by a third-party intermediary.</li>
                <br/>
                <li>Public:  Some of our Sites may provide the opportunity to post comments, or reviews, in a public forum.  If you decide to submit information on these pages, that information may be publicly available. </li>
                <br/>
                <li>Service Providers. We share your information with service providers.  Among other things, service providers help us administer our Sites, conduct surveys, provide technical support, process payments, and assist in the fulfillment of orders. Legitimate Reasons for Processing</li>
                <br/>
            </ul>
        </Typography>

            <br/>
            <br/>

        <Typography>
        Your Personal Information: 
        <br/>
        <br/>
        We only collect and use your personal information when we have a legitimate reason for doing so. In this instance, we only collect personal information that is reasonably necessary to provide our services to you.
        <br/>
        <br/>
        Security of Your Data:
        <br/>
        <br/>
        Although we will do our best to protect the personal information you provide to us, no method of electronic transmission or storage is fully secure, and no one can guarantee absolute data security. While we use reasonable efforts to protect your personal information from unauthorized access, use, or disclosure, we cannot guarantee the security of your personal information. If we are required by law to inform you of a breach of your personal information, we will comply with laws applicable to us and may notify you electronically, in writing, or by telephone, if permitted to do so by law. When you create your account, you will be prompted to create a password You are responsible for selecting any password, its overall security strength, and ensuring the security of your own information within the bounds of our Services. You are responsible for any access to or use of your account by someone else that has obtained your password, whether such access or use has been authorized by you. You should notify us of any unauthorized use of your password or account.
        </Typography>

        <Typography>
        Your Rights
        <br/>
        <br/>
        You can make the following choices regarding your personal information:
        <br/>
        <br/>
        <ul>
            <li>Access To Your Personal Information: You may request access to your personal information by contacting us at the address described below. If required by law, upon request, we will grant you reasonable access to the personal information that we have about you.  We will provide this information in a portable format if required.  Note that California residents may be entitled to ask us for a notice describing what categories of personal information (if any) we share with third parties or affiliates for direct marketing.  </li>
            <br/>
            <li>Changes To Your Personal Information: We rely on you to update and correct your personal information.  Most of our websites allow you to modify or delete your account profile.  If our Sites do not permit you to update or correct certain information, you contact us at the address described below to request that your information be modified. Note that we may keep historical information in our backup files as permitted by law.  </li>
            <br/>
            <li>Deletion Of Your Personal Information: Typically, we retain your personal information for the period necessary to fulfill the purposes outlined in this policy unless a longer retention period is required or permitted by law, for example for our compliance with a legal, accounting, or reporting obligation or for archiving purposes in the public interest, scientific, or historical research purposes or statistical purposes.  You may, however, request information about how long we keep a specific type of information or request that we delete your personal information by contacting us at the address described below.  If required by law we will grant a request to delete information, but you should note that in many situations we must keep your personal information to comply with our legal obligations, resolve disputes, enforce our agreements, or for another one of our business purposes.  </li>
            <br/>
            <li>Objection to Certain Processing: You may object to our use or disclosure of your personal information by contacting us at the address described below.  </li>
            <br/>
            <li>Online Tracking: We do not currently recognize automated browser signals regarding tracking mechanisms, which may include “Do Not Track” instructions. </li>
            <br/>
            <li>Promotional Emails: You may choose to provide us with your email address for the purpose of allowing us to send free newsletters, surveys, offers, and other promotional materials to you, as well as targeted offers from third parties.  You can stop receiving promotional emails by following the unsubscribe instructions in the e-mails that you receive.  If you decide not to receive promotional emails, we may still send you service-related communications. </li>
            <br/>
            <li>Promotional Text Messages:  If you receive a text message from us that contains promotional information you can opt out of receiving future text messages by replying “STOP.”</li>
            <br/>
            <li>Revocation Of Consent: If you revoke your consent for the processing of personal information then we may no longer be able to provide you services. In some cases, we may limit or deny your request to revoke consent if the law permits or requires us to do so, or if we are unable to adequately verify your identity. You may revoke consent to processing (where such processing is based upon consent) by contacting us at the address described below.</li>
            <br/>
        </ul>
        </Typography>
        <br/>
        <br/>
        <Typography>
            Please address written requests and questions about your rights to info@getosmosis.io  
            Note that, as required by law, we will require you to prove your identity.  We may verify your identity by phone call or email. Depending on your request, we will ask for information such as your name or other account information, or your last account activity. We may also ask you to provide a signed declaration confirming your identity. Following a request, we will use reasonable efforts to supply, correct, or delete personal information about you in our files.
            <br/>
            <br/>
            In some circumstances, you may designate an authorized agent to submit requests to exercise certain privacy rights on your behalf.  We will require verification that you provided the authorized agent permission to make a request on your behalf.  You must provide us with a copy of the signed permission you have given to the authorized agent to submit the request on your behalf and verify your own identity directly with us.  If you are an authorized agent submitting a request on behalf of an individual, you must attach a copy of the following information to the request:
            <br/>
            <br/>
            1.	A completed written notice indicating that you have authorization to act on the consumer’s behalf is signed by you and the consumer.
            <br/>
            <br/>
            2.	If you are a business, proof that you are registered with the appropriate Secretary of State to conduct business in California.
            <br/>
            <br/>
            If we do not receive both pieces of information, the request will be denied.
            <br/>
            <br/>
        </Typography>
        <Typography>
        Other Important Information:
        <br/>
        <br/>
        Jurisdiction-Specific Rules.  If you live in certain states, countries, or regions, you may have certain rights related to accessing and deleting your data, as well as learning whom we share your data with. 
        <br/>    
        <br/>
        Users Outside of the U.S. Osmose is in the United States.  Our service providers and other third parties you may interact with in connection with our Services may be in the United States and other countries around the world. As a result, your information may be processed in a foreign country where privacy laws may be less stringent than the laws in your country.  Nonetheless, where possible we take steps to treat personal information using the same privacy principles that apply pursuant to the law of the country in which we first received your information.  By submitting your personal information to us you agree to the transfer, storage, and processing of your information in a country other than your country of residence including, but not necessarily limited to, the United States.  To the extent personal information is collected and subsequently transferred out of the European Economic Area, the transfer will take place consistent with the Standard Contractual Clauses. If you would like more information concerning our attempts to apply the privacy principles applicable in one jurisdiction to data when it goes to another jurisdiction you can contact us using the contact information below.
        <br/>    
        <br/>
        Users in California.  California law indicates that organizations should disclose whether certain categories of information are collected, “sold” or transferred for an organization’s “business purpose” (as those terms are defined under California law). We do not discriminate against California residents who exercise any of their rights described in this Privacy Notice.
        <br/>    
        <br/>
        Users in Nevada. Osmose does not sell its users’ personal information or personal data. Nonetheless, Nevada residents have the right to submit a request that we do not sell your covered personal information, which you can do by emailing us at info@getosmosis.io
        <br/>    
        <br/>
        Use of Cookies.  We use “cookies” to collect information about you and your activity across our Sites. A cookie is a small piece of data that our Sites store on your computer, and accesses each time you visit, so we can understand how you use our Sites. This helps us serve you content based on the preferences you have specified.
        <br/>    
        <br/>
        Children’s Privacy. We do not aim any of our Services directly at children under the age of 13, and we do not knowingly collect personal information about children under 13.
        <br/>    
        <br/>
        Accessibility. If you are visually impaired, you may access this notice through your browser’s audio reader.
        <br/>    
        <br/>
        Third-Party Applications/Websites.  Our Sites may link to external sites that are not operated by us. Please be aware that we have no control over the content and policies of those sites and cannot accept responsibility or liability for their respective privacy practices.
        <br/>    
        <br/>
        Changes to This Policy. At our discretion, we may change our Privacy Notice to reflect updates to our business processes, current acceptable practices, or legislative or regulatory changes. If we decide to change this Privacy Notice, we will post the changes here at the same link by which you are accessing this Privacy Notice.  If required by law, we will get your permission or give you the opportunity to opt in to or opt out of, as applicable, any new uses of your personal information.
        <br/>    
        <br/>
        Contact Us. For any questions or concerns regarding your privacy, you may contact us using the following details:
        <br/>    
        <br/>
        info@getosmosis.io
        <br/>    
        Osmose, Inc. New York, NY USA
        <br/>    
        <br/>

        </Typography>


      </Container>
    );
  } 

export default Privacy;
